import React from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "../../index.css";

const PickedNameModal = (props) => {
  return (
    <>
      <Modal
        dialogClassName="modalDialog"
        className="pickedNameModal"
        show={props.showPickedNameModal}
        onHide={() => props.setShowPickedNameModal(false)}
      >
        <Modal.Body className="pickedNameBody">
          <Row>
            <Col
              style={{
                padding: 0,
                margin: "10px 40px 10px 40px",
                textAlign: "center",
              }}
            >
              <>
                <p className="nameTitle">{props.selectedName}</p>
                <Button
                  className="keepNameButton"
                  onClick={() => props.setShowPickedNameModal(false)}
                >
                  Keep Name
                </Button>
                <Button
                  className="removeNameButton"
                  onClick={(event) => props.handleRemoveRow(event)}
                >
                  Remove Name
                </Button>
              </>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PickedNameModal;
