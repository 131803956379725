import React from "react";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import trashIcon from "../../images/trash_icon.svg";
import plusIcon from "../../images/plus_button.svg";
import "../../index.css";

const NameField = (props) => {
  const updateRow = (event, index) => {
    event.preventDefault();
    let localArr = [...props.names];
    localArr[index] = event.target.value;

    switch (props.header) {
      case "Read Letter": {
        props.setLetterNames(localArr);
        break;
      }
      case "Give Experience": {
        props.setExperienceNames(localArr);
        break;
      }
      case "Phone Call": {
        props.setPhoneCallNames(localArr);
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleAddRow = (event) => {
    event.preventDefault();
    let localArr = [...props.names];
    localArr.push("");

    switch (props.header) {
      case "Read Letter": {
        props.setLetterNames(localArr);
        break;
      }
      case "Give Experience": {
        props.setExperienceNames(localArr);
        break;
      }
      case "Phone Call": {
        props.setPhoneCallNames(localArr);
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleRemoveRow = (event, i) => {
    event.preventDefault();
    let localArr = [...props.names];
    localArr.splice(i, 1);

    switch (props.header) {
      case "Read Letter": {
        props.setLetterNames(localArr);
        break;
      }
      case "Give Experience": {
        props.setExperienceNames(localArr);
        break;
      }
      case "Phone Call": {
        props.setPhoneCallNames(localArr);
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <>
      <h3 className="nameFieldHeader">{props.header}</h3>
      {props.names.map((value, index) => {
        return (
          <Row key={index}>
            <Form.Control
              type="text"
              className="nameField"
              id={index}
              value={value}
              onChange={(event) => updateRow(event, index)}
            />
            <button className="invisibleButton">
              <img
                alt=""
                className="removeButton"
                onClick={(event) => handleRemoveRow(event, index)}
                src={trashIcon}
              ></img>
            </button>
          </Row>
        );
      })}
      <Row>
        <button className="invisibleButton">
          <img
            alt=""
            className="addButton"
            onClick={handleAddRow}
            src={plusIcon}
          ></img>
        </button>
      </Row>
    </>
  );
};

export default NameField;
