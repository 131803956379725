import React, { Component } from "react";
import MainLanding from "./components/MainLanding.js";
import "./App.css";

class App extends Component {
  render() {
    return <MainLanding />;
  }
}
export default App;
