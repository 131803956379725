import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Wheel from "./wheel/wheel.js";
import NameField from "./nameFields/nameField.js";
import PickedNameModal from "./popup/pickedNameModal.js";
import "../index.css";

const MainLanding = (props) => {
  const [selectedOption, setSelectedOption] = useState("letter");
  const [letterNames, setLetterNames] = useState([""]);
  const [experienceNames, setExperienceNames] = useState([""]);
  const [phoneCallNames, setPhoneCallNames] = useState([""]);
  const [selectedName, setSelectedName] = useState("");
  const [selectedIndex, setSelectedIndex] = useState("");
  const [showPickedNameModal, setShowPickedNameModal] = useState(false);

  const activateModal = (pickedName) => {
    setSelectedIndex(pickedName);

    switch (selectedOption) {
      case "letter": {
        setSelectedName(letterNames[pickedName]);
        setShowPickedNameModal(true);
        break;
      }
      case "experience": {
        setSelectedName(experienceNames[pickedName]);
        setShowPickedNameModal(true);
        break;
      }
      case "phone": {
        setSelectedName(phoneCallNames[pickedName]);
        setShowPickedNameModal(true);
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleRemoveRow = (event) => {
    event.preventDefault();
    switch (selectedOption) {
      case "letter": {
        let localArr = [...letterNames];
        localArr.splice(selectedIndex, 1);
        setLetterNames(localArr);
        setShowPickedNameModal(false);
        break;
      }
      case "experience": {
        let localArr = [...experienceNames];
        localArr.splice(selectedIndex, 1);
        setExperienceNames(localArr);
        setShowPickedNameModal(false);
        break;
      }
      case "phone": {
        let localArr = [...phoneCallNames];
        localArr.splice(selectedIndex, 1);
        setPhoneCallNames(localArr);
        setShowPickedNameModal(false);
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <div className="App">
      <PickedNameModal
        selectedName={selectedName}
        showPickedNameModal={showPickedNameModal}
        setShowPickedNameModal={setShowPickedNameModal}
        handleRemoveRow={handleRemoveRow}
      />

      <Container className="radioContainer">
        <input
          type="radio"
          className="radioButton"
          checked={selectedOption === `letter`}
          onChange={() => setSelectedOption("letter")}
        />
        <label className="radioText" style={{ marginRight: 20 }}>
          Read Letter
        </label>
        <input
          type="radio"
          className="radioButton"
          checked={selectedOption === `experience`}
          onChange={() => setSelectedOption("experience")}
        />
        <label className="radioText" style={{ marginRight: 20 }}>
          Experience
        </label>
        <input
          type="radio"
          className="radioButton"
          checked={selectedOption === `phone`}
          onChange={() => setSelectedOption("phone")}
        />
        <label className="radioText">Phone Call</label>
      </Container>

      {selectedOption === "letter" && (
        <>
          <h1 className="titleHeader">Who Is Reading A Letter?</h1>
          <Row>
            <Wheel
              items={letterNames.filter((name) => name !== "")}
              activateModal={activateModal}
            />
          </Row>
        </>
      )}
      {selectedOption === "experience" && (
        <>
          <h1 className="titleHeader">Who Is Giving An Experience?</h1>
          <Row>
            <Wheel
              items={experienceNames.filter((name) => name !== "")}
              activateModal={activateModal}
            />
          </Row>
        </>
      )}
      {selectedOption === "phone" && (
        <>
          <h1 className="titleHeader">Who Is Making A Phone Call?</h1>
          <Row>
            <Wheel
              items={phoneCallNames.filter((name) => name !== "")}
              activateModal={activateModal}
            />
          </Row>
        </>
      )}
      <Container className="nameFieldContainer">
        <Row>
          <Col className="nameFieldCol">
            <NameField
              header="Read Letter"
              names={letterNames}
              setLetterNames={setLetterNames}
            />
          </Col>
          <Col className="nameFieldCol">
            <NameField
              header="Give Experience"
              names={experienceNames}
              setExperienceNames={setExperienceNames}
            />
          </Col>
          <Col className="nameFieldCol">
            <NameField
              header="Phone Call"
              names={phoneCallNames}
              setPhoneCallNames={setPhoneCallNames}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MainLanding;
